
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import OverviewChart from './Overviewchart.vue';
import IconStart from '@/components/Icon/IconstartCircle.vue';
import IconDownload from '@/components/Icon/IconDownload.vue';
import IconTransaction from '@/components/Icon/IconTransaction.vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
// import IconPath from '@/components/Icon/IconPath.vue';
import constRouter from '@/constants/constRouter';
import { overviewPool } from '@/apis/ApiPoolInvestment';
import { numberToMoney, formatDay } from '@/utils/index';
import { mapGetters, mapMutations } from 'vuex';
export default defineComponent({
  components: {
    DefaultLayout,
    // OverviewChart,
    IconStart,
    IconDownload,
    IconTransaction,
    // IconPath,
    BaseModal,
    BaseButton,
  },
  data() {
    return {
      numberToMoney,
      formatDay,
      loading: false,
      openModal: false,
      // chartFilter: 1,
      // labelsChart: [],
      // dataChartinvestProfits: [123, 31, 123, 11],
      // chartOption: {
      //   legend: {
      //     //hides the legend
      //     display: false,
      //   },
      //   // scales: {
      //   //   //hides the y axis
      //   //   yAxes: [
      //   //     {
      //   //       display: false,
      //   //     },
      //   //   ],
      //   //   xAxes: [
      //   //     {
      //   //       display: false,
      //   //     },
      //   //   ],
      //   // },
      // },
    };
  },
  created() {
    const id: any = this.$route.params.id;
    if (this.data.id !== id) {
      this.setOverview({});
      this.fetchData(id);
    }
  },
  computed: {
    ...mapGetters({
      data: 'getOverviewPool',
    }),
  },
  methods: {
    ...mapMutations({
      setOverview: 'setOverview',
    }),
    fetchData(id: string) {
      this.loading = true;
      overviewPool(id)
        .then((res) => {
          this.loading = false;
          this.setOverview(res.data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    handleviewContract() {
      window.open(this.data.contract, '_blank');
    },
    handleHistoryTransfer() {
      this.$router.push({
        name: constRouter.OVERVIEW_HISTORY_TRANSACTION.name,
        params: { id: this.data.id },
      });
    },
    handleWithdrawMoney() {
      if (this.data.statusCode !== 2) {
        this.openModal = true;
      } else {
        this.$router.push({
          name: constRouter.OVERVIEW_WITHDRAWMONEY.name,
          params: { id: this.$route.params.id },
        });
      }
    },
    closeModal() {
      this.openModal = false;
    },
    onCopy(data: string) {
      navigator.clipboard.writeText(data);
    },
  },
});
